import React from "react"
import Container from 'react-bootstrap/Container';

const Gallery = () => {
    return (
      <Container>
   <iframe className="flex-item" src="https://www.youtube.com/embed/ExijRN8C8U0?si=pw6ww03jTO93Ff9y" 
   title="YouTube video player" 
   frameborder="0" 
   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
   referrerpolicy="strict-origin-when-cross-origin" 
   allowfullscreen></iframe>
     </Container>
    )
  };
  
  export default Gallery;