
const OurTeam = () => {
    return (

   
   <p>Our Team</p>

      
    
  )
};

export default OurTeam;