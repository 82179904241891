import logo from './peterboroughsaintslogo.jpg';
import southholland from './southholland.jpg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Image from 'react-bootstrap/Image'


const Home = () => {
    return (
    
    <Container className='home'>
       <Row>
       <Col><Image src={southholland} className="img-fluid" alt="logo" /></Col>
     </Row>
        <Row>
       <Col>Peterborough Saints Sunday League</Col>
     </Row>
     <Row>
       <Col><img src={logo} className="App-logo" alt="logo" /></Col>
     </Row>
     <Row>
       <Col>Founded in the heart of Peterborough, our Sunday League football team brings together a diverse group of local football enthusiasts with a shared passion for the beautiful game. 
       We may not be professionals, but we play with heart, determination, and a sense of community that defines grassroots football. 
       Whether it's a nail-biting match or a casual kickabout, we're all about teamwork, sportsmanship, and having a good time on and off the pitch.  
       With players from various backgrounds and skill levels, we strive to improve with every game while keeping the fun at the forefront. 
       Win or lose, we support each other like family and enjoy the camaraderie that comes with playing the sport we love.  
       Join us for a match, and you’ll see why football is more than just a game—it's a way of life for the Peterborough Saints!</Col>
     </Row>
       
     <Row>
     </Row>
     </Container>
    )
  };
  
  export default Home;
    