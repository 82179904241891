import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import emailjs from '@emailjs/browser';



const ContactUs = () => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID='service_ndej4g5',
        process.env.REACT_APP_TEMPLATE_ID='template_sc1log7',
        e.target,
        process.env.REACT_APP_PUBLIC_KEY='vD4OX6kb0VOZ0s2pE'
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };

    return (

 
      <Form onSubmit={sendEmail}>


      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" id='from_name' name='from_name' placeholder="Name" />
     
        <Form.Label>Phone Number</Form.Label>
        <Form.Control type="number" id='from_phone_number' name='from_phone_number' placeholder="Phone Number" />
    
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" id='from_email' name='from_email' placeholder="name@example.com" />
   
        <Form.Label>Question</Form.Label>
        <Form.Control as="textarea" id='message' name='message'  placeholder="Please ask your query" rows={3} />
   

         <Button variant="primary" type="submit" value="Send" disabled={isSubmitting}>
          Submit
        </Button>
        {stateMessage && <p>{stateMessage}</p>}
           </Form.Group>
      </Form>
  
    )
    
  };
  
  export default ContactUs;


     








 

