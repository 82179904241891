import './App.css';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route} from "react-router-dom";
import Home from "./Home";
import UpcomingFixtures from "./UpcomingFixtures";
import ContactUs from './ContactUs';
import OurTeam from './OurTeam';
import Gallery from './Gallery'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import logo from './peterboroughsaintslogo.jpg';
import React, {useState} from 'react';




function App() {

  const [expanded, setExpanded] =
  useState(false);
  
  const handleToggle = () => {setExpanded(!expanded);
  } ;

  const handleSelect = () => {
    setExpanded(false);
  };

  return (
    
    <div className="App">
      <header className="App-header">
     
      
      <Navbar fixed="bottom" className="custom-navbar"  expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="#home" classname='d-flex align-items-center'><img src={logo} className="App-logo-header" alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
          <Navbar.Collapse id="navbar-nav" className='flex-column align-items-end'>
          <Nav className="me-auto" onSelect={handleSelect}>
            <Nav.Link href="#action/3.1" as={Link} to="/">Home</Nav.Link>
            <Nav.Link href="#action/3.2" as={Link} to="/upcomingfixtures">Upcoming Fixtures</Nav.Link>
            <Nav.Link href="#action/3.3" as={Link} to="/ourteam">Our Team</Nav.Link>
            <Nav.Link href="#action/3.4" as={Link} to="/gallery">Gallery</Nav.Link>
            <Nav.Link href="#action/3.5" as={Link} to="/contactus">Contact Us</Nav.Link >
          </Nav>
          </Navbar.Collapse>
          </Container>
          </Navbar>


          <Container>
          <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="upcomingfixtures" element={<UpcomingFixtures />} />
          <Route path="ourteam" element={<OurTeam/>} />
          <Route path="gallery" element={<Gallery/>} />
          <Route path="contactus" element={<ContactUs/>} />
          </Routes>
          </Container>
    
  
     
         

  

      </header>
      <footer  className="App-footer">
      <Button variant="primary" href="https://www.facebook.com/share/JUgBirs5s3MMeryE/?mibextid=WC7FNe">Facebook</Button>{' '}
      <Button variant="secondary"  href="https://www.instagram.com/peterboroughsaints_fc/" >Instagram</Button>{' '}
      </footer>
     
    </div>
  );
}

export default App;
